class communityspacehomepage extends lm.Composant {
  /**
   * @constructor
   * @param {String} id
   */
  constructor(id) {
    super(id);
    const jsToggleTextButton = document.querySelector('.js-toggle-text');
    const textToDisplay = document.getElementById("o-hero__main-baseline");

    // Toggle to display full text in introduction
    if (jsToggleTextButton) {
      jsToggleTextButton.addEventListener('click', e => {
        e.preventDefault();
        if (textToDisplay) {
          textToDisplay.classList.toggle("o-hero__main-baseline--reduced");
          const message = jsToggleTextButton.getAttribute('data-toggle-message');
          jsToggleTextButton.setAttribute('data-toggle-message', jsToggleTextButton.innerText);
          jsToggleTextButton.innerText = message;
        }
      });
    }
  }
}
lm.DOMReady(() => {
  new communityspacehomepage('communityspacehomepage');
});